import { ButtonType } from './@types/button';
import { ROUTES } from './config';

export const platformItems = [
  {
    name: 'Carbon Accounting',
    path: ROUTES.PRODUCTS.MEASUREMENT,
    sections: [
      { label: 'Overview', path: ROUTES.PRODUCTS.MEASUREMENT },
      {
        label: 'Reporting & Disclosure',
        path: ROUTES.PRODUCTS.REPORTING
      },
      { label: 'Marketplace', path: ROUTES.PRODUCTS.MARKETPLACE },
      { label: 'Directory', path: ROUTES.PRODUCTS.DIRECTORY }
    ]
  }
  // {
  //   name: 'Carbon Removal Platform',
  //   path: null,
  //   // path: ROUTES.PRODUCTS.REMOVAL,
  //   sections: [
  //     { label: 'Overview', path: 'products/removal' },
  //     { label: 'Open Impact Standard', path: 'products/ois' },
  //     { label: 'Collaboration', path: 'products/collaboration' },
  //     { label: 'Blockchain', path: 'products/blockchain' }
  //   ],
  //   comingSoon: true
  // }
] as ButtonType[];

export const resourceItems = [
  {
    name: 'About',
    path: ROUTES.RESOURCES.ABOUT,
    sections: [],
    titleOnly: true
  },
  // {
  //   name: 'Carbon Removal Platform',
  //   path: null,
  //   // path: ROUTES.PRODUCTS.REMOVAL,
  //   sections: [
  //     { label: 'Overview', path: 'products/removal' },
  //     { label: 'Open Impact Standard', path: 'products/ois' },
  //     { label: 'Collaboration', path: 'products/collaboration' },
  //     { label: 'Blockchain', path: 'products/blockchain' }
  //   ],
  //   comingSoon: true
  {
    name: 'Contact Us',
    path: ROUTES.COMPANY.CONTACT,
    sections: [],
    titleOnly: true
  },
  {
    name: 'Blog',
    path: ROUTES.RESOURCES.BLOG.LIST,
    sections: [],
    titleOnly: true
  }
] as ButtonType[];

// export const solutionsItems = [
//   {
//     name: 'For Auditors',
//     description: `Audit an entire organization's carbon footprints efficiently. Rely on detailed emission factors to ensure accuracy.`,
//     path: ROUTES.SOLUTIONS.FOR_AUDITORS
//   },
//   {
//     name: 'For Project Developers',
//     description:
//       'Perform pre-feasibility, Validation, Financing, and more. Sell it on our marketplace after verification.',
//     path: ROUTES.SOLUTIONS.FOR_PROJECT_DEVELOPERS
//   },
//   {
//     name: 'For Organizations',
//     description:
//       'Lead by example and achieve carbon neutrality. Learn how sustainability can increase your bottom line.',
//     path: ROUTES.SOLUTIONS.FOR_ORGANIZATIONS
//   },
//   {
//     name: 'For Investors',
//     description:
//       'Invest in carbon removal and environment assets in the marketplace. Pre-fund projects to insure yourself against decreasing carbon allowances.',
//     state: 'new',
//     path: ROUTES.SOLUTIONS.FOR_INVESTORS
//   }
// ] as ButtonType[];

export const FOOTER_SECTIONS = [
  {
    name: 'Products',
    items: platformItems
  },
  {
    name: 'Resources',
    items: [
      { name: 'Pricing', path: ROUTES.RESOURCES.PRICING },
      { name: 'About', path: ROUTES.RESOURCES.ABOUT },
      { name: 'Contact', path: ROUTES.COMPANY.CONTACT }
      // { name: 'Careers', path: ROUTES.COMPANY.CAREERS }
    ]
  }
] as {
  name: string;
  items: ButtonType[];
}[];
